& .react-datepicker__month {
    margin: 0.4rem;
    text-align: center;
  }

  & .react-datepicker__day {
    color: #000;
    display: inline-block;
    width: 2.7rem;
    line-height: 2rem;
    text-align: center;
    margin: 0.16rem;

    @media screen and (max-width: 420px) {
      width: 1.8rem;
    }
  }

  & .react-datepicker__day:hover {
    color: #000;
  }

  & .react-datepicker__day--disabled {
    color: #ccc;
  }

  & .react-datepicker__day-name {
    color: #000;
    display: inline-block;
    width: 3rem;
    line-height: 2rem;
    text-align: center;
    margin: 0.100rem;
    font-size: 0.9rem;
    margin-top: 10px;
    font-style: italic;

    @media screen and (max-width: 420px) {
      width: 1.8rem;
    }
  }

  & .react-datepicker__day--selected {
    background-color: #00A099;
    color: white;
    border: 1px solid #8CBB93;
  }

  & .react-datepicker__day--selected:hover {
    background-color: #00A099;
    color: white;
    border: 1px solid #8CBB93;
  }

  & .react-datepicker__header {
    text-align: center;
    text-transform: capitalize;
    background-color: #eff2f8;
    border-bottom: 1px solid #aeaeae;
    padding: 8px 0;
    position: relative;
    width: 350px;
    border-radius: 0.3rem; 

    @media screen and (max-width: 420px) {
      width: 270px;
    }
  }


  & .react-datepicker {
    font-family: "Helvetica Neue", helvetica, arial, sans-serif;
    font-size: 0.8rem;
    background-color: #fff;
    color: #000;
    border: 2px solid #94a5c0;
    display: inline-block;
    position: relative;
    border-radius: 0.5rem; 

  }

  & .react-datepicker__current-month {
    font-size: 1.2rem;
    margin-top: 0.2rem;
    margin-bottom: 0.2rem;
    text-align: center;
    font-weight: bold;
  }

  & .react-datepicker__navigation {
    -webkit-align-items: center;
    -moz-box-align: center;
    align-items: center;
    background: none;
    display: -webkit-flex;
    display: -moz-box;
    display: flex;
    -webkit-justify-content: center;
    -moz-box-pack: center;
    justify-content: center;
    text-align: center;
    cursor: pointer;
    position: absolute;
    top: 2px;
    padding: 0;
    border: none;
    z-index: 1;
    height: 32px;
    width: 32px;
    text-indent: -999em;
    overflow: hidden;
}

.react-datepicker__navigation--previous {
    left: 25px;
    margin-top: 5px;
    border-radius: 20%;
}

.react-datepicker__navigation--next {
    right: 25px;
    margin-top: 5px;
    border-radius: 20%;
}