.mercadopago-button {
    padding: 0 !important;
    font-family: "Helvetica Neue", Arial, sans-serif;
    font-size: 20px !important;
    line-height: 2.7142857142857144;
    background: #00A099 !important;
    color: #fff;
    cursor: pointer;
    border: 0;
    width: 30vw !important;
}